import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ClassModeMethods extends Vue {
  // 授業モード定義
  readonly CLASS_MODE = {
    NORMAL: 'TJ', // 通常授業
    TEST: 'TK', // 定期テスト
    SEMINAR: 'KS', // 講習会
    ENTRANCE: 'NY', // 入試対策
    ASSIST: 'HJ', // 補助教材
    MISTAKE: 'MN', // 間違い直し
    AI: 'AI', // AI学習
  }

  private readonly CLASS_MODE_TEXT = {
    TJ: '通常',
    TK: '対策',
    KS: '講習会',
    NY: '入試',
    HJ: '補助教材',
    MN: '間違い直し',
    AI: 'AI学習',
  }

  /**
   * クラスモードの配列を取得する
   */
  protected get classModeCodes(): string[] {
    return Object.values(this.CLASS_MODE)
  }

  /**
   * 有効なクラスモードかを判定する
   * @param code string
   */
  protected isValidClassMode(code: string): boolean {
    return this.classModeCodes.includes(code)
  }

  /**
   * 各クラスモードの表示名を返却する
   * @param code TJ | TK | KS | NY | HJ | MN | AI
   */
  protected classModeText(code: string) {
    return this.CLASS_MODE_TEXT[code]
  }

  // 補助教材コード
  readonly ASSIST_MATERIAL_CODE = { OO: 'OO', ST: 'ST', HS: 'HS', HD: 'HD', FC: 'FC', KT: 'KT' }

  private readonly ASSIST_MATERIAL_TEXT = {
    OO: '一問一答ドリル',
    ST: '診断テスト',
    HS: '復習テスト',
    // HD: '補助動画', TODO: 補助動画が追加され次第コメントアウト解除
  }

  // 選択可能教科
  // 今後他のデータを入れることが可能
  private readonly ASSIST_MATERIAL_DATA = {
    OO: {
      subjectCodes: ['ei', 'ri', 'sh'],
    },
    ST: {
      subjectCodes: ['su', 'ei', 'ko', 'ri', 'sh'],
    },
    HS: {
      subjectCodes: ['su', 'ei', 'ko', 'ri', 'sh'],
    },
    HD: {
      subjectCodes: ['su', 'ei', 'ko', 'ri', 'sh'],
    },
    FC: {
      subjectCodes: ['su', 'ei', 'ko', 'ri', 'sh'],
    },
    KT: {
      subjectCodes: ['su', 'ei', 'ko', 'ri', 'sh'],
    },
  }

  /**
   * クラスモードの配列を取得する
   */
  protected get assistMaterialCodes(): string[] {
    return Object.values(this.ASSIST_MATERIAL_CODE)
  }

  /**
   * {コード:表示名} 一覧を返却する
   */
  protected get assistMaterialTexts() {
    return this.ASSIST_MATERIAL_TEXT
  }

  /**
   * 補助教材モードの表示名を返却する
   * @param code OO | ST | HS | HD
   */
  protected assistMaterialText(code: string) {
    return this.ASSIST_MATERIAL_TEXT[code]
  }

  /**
   * 各クラスモードの選択可能教科を返却する
   * @param code OO | ST | HS | HD
   */
  protected assistMaterialSubjectsCode(code: string): string[] {
    return this.ASSIST_MATERIAL_DATA[code]?.subjectCodes || []
  }

  // 塾専用サービスをもつ塾コード
  readonly PRIVATE_SERVICE_ACADEMY_CODE = 'zenkyoken'

  // 塾専用サービス（製品）コード
  readonly PRIVATE_SERVICE_DATA = {
    assistMaterialCode: 'HD',
    serviceCode: 'ZK', // 全教研専用補助動画
    subjectCodes: ['su', 'ri'], // 全教研教科コード
    defaultGradeCode: 's6', // 標準の学年コード
    defaultPublisherCode: 'hy', // 標準の教科書コード
  }
  readonly PRIVATE_CONFIRM_TEST_SERVICE_DATA = {
    assistMaterialCode: 'KT',
    serviceCode: 'ZK', // 全教研専用補助動画
    subjectCodes: ['su'], // 全教研教科コード
    defaultGradeCode: 's6', // 標準の学年コード
    defaultPublisherCode: 'hy', // 標準の教科書コード
  }
  readonly PRIVATE_FINAL_CHECK_SERVICE_DATA = {
    assistMaterialCode: 'FC',
    serviceCode: 'ZF', // 全教研専用補助動画
    subjectCodes: ['su', 'ri'], // 全教研教科コード
    defaultGradeCode: 's6', // 標準の学年コード
    defaultPublisherCode: 'hy', // 標準の教科書コード
  }

  readonly MAP_NAME = {
    HD_ZK: '',
    KT_ZK: '_CONFIRM_TEST',
    FC_ZF: '_FINAL_CHECK',
  }

  readonly MAP_SERVICE_DATA = {
    HD: 'ZK',
    KT: 'ZK',
    FC: 'ZF',
  }

  readonly PRIVATE_SERVICE_CODE_TEXT = {
    ZK: `全教研専用中受コース`,
    HD: `全教研専用中受コース`,
    FC: `ファイナルチェック`,
    KT: '確認テスト',
  }

  protected get privateServiceTexts() {
    return this.PRIVATE_SERVICE_CODE_TEXT
  }

  protected get privateData() {
    const dataGdls = Vue.prototype.$cookies.get('dataGdls')
    const serviceCode = dataGdls.serviceCode || ''
    const assistMaterialCode = this.$route.params.assistMaterialCode || dataGdls.assistMaterialCode || ''
    const privateName = this.MAP_NAME[assistMaterialCode + '_' + serviceCode]
    return this[`PRIVATE${privateName}_SERVICE_DATA`] || {}
  }

  protected getPrivateDate(assistMaterialCode: string, serviceCode: string) {
    const privateName = this.MAP_NAME[assistMaterialCode + '_' + serviceCode]
    return this[`PRIVATE${privateName}_SERVICE_DATA`] || {}
  }

  // 現在のクラスモード取得
  protected get currentClassMode() {
    const isReport = this.$route.path.includes('report')
    if (isReport) {
      return this.$route.query?.classMode || Vue.prototype.$cookies.get('dataGdls').classMode
    }
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  // 通常モード
  protected get isNormalMode(): boolean {
    return this.currentClassMode == this.CLASS_MODE.NORMAL
  }

  // 定期テスト対策モード
  protected get isTestMode(): boolean {
    return this.currentClassMode == this.CLASS_MODE.TEST
  }

  // 講習会モード
  protected get isSeminarMode(): boolean {
    return this.currentClassMode == this.CLASS_MODE.SEMINAR
  }

  // 入試対策モード
  protected get isEntranceMode(): boolean {
    return this.currentClassMode == this.CLASS_MODE.ENTRANCE
  }

  // 補助教材モード
  protected get isAssistMode(): boolean {
    return this.currentClassMode == this.CLASS_MODE.ASSIST
  }
}
